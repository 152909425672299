$font-path: "./assets/font/";
$image-path: "./assets/img/";
$main-font-family: "Centra", sans-serif;
$main-font-color: #fff;
$primary-bg-color: #121212;
$secondary-bg-color: #000;
$accent-color: #46b076;
$base-font-size: 16px;
$line-height: 1.5;
$common-transition: 0.3s ease-in-out;

@import "animate.css";

@font-face {
  font-family: Centra;
  src: url("#{$font-path}CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("#{$font-path}CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("#{$font-path}CentraNo2-Book.ttf");
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 125px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: $primary-bg-color;
  color: $main-font-color;
  font-family: $main-font-family;
}

h1, h2, h3, h4, h5, h6, p, a, li, button, ul {
  font-size: $base-font-size; 
  line-height: $line-height; 
  text-decoration: none;
  margin: 25px 0px 25px 0px;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}
p.danger {
  color: red;
}

#logo{
  width: 160px;
}
nav.navbar {
  padding: 18px 0;
background-color: #222;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled{
  padding: 0;
  background-color: #121212;
}

nav.navbar.toggled {
  padding: 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 20%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
  font-size: 18px;
}

.navbar-link.nav-link:before,
.navbar-link.nav-link:after {
  color: #46b076;
  opacity: 0;
  font-size: 25px;
  top: 0px;
  transition: all 0.3s;
}

.navbar-link.nav-link:before {
  content: '{';
  left: 0px;
  transform: translateX(10px);
}

.navbar-link.nav-link:after {
  content: '}';
  right: 0px;
  transform: translateX(-10px);
}

.navbar-link.nav-link:hover:before,
.navbar-link.nav-link:hover:after {
  opacity: 1;
  transform: translateX(0px);
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: whitesmoke;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #46b076;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  width: 100%;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 0.3s linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 0.3s linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after,
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}


/* ... (rest of your existing CSS) ... */

/* Media query for screens less than 1000px wide */
@media (max-width: 1000px) {
  /* Adjust the navbar items for mobile */
  nav.navbar .navbar-nav {
    width: 100%;
    text-align: center;
  }

  nav.navbar .navbar-nav .nav-link.navbar-link {
    display: block;
    width: 100%;
    
  }

  /* Adjust the navbar button for mobile */
  .navbar-text button {
    display: block;
    width: 100%;
    margin-top: 20px; /* Add some space above the button */
  }

  /* Adjust the social icons for mobile */
  .social-icon {
    display: flex;
    justify-content: center; /* Center the icons horizontally */
    margin-bottom: 20px; /* Add some space below the icons */
  }

  .social-icon a {
    margin: 0 5px; /* Add some space between the icons */
  }
}

@media (max-width: 767px) {
  nav.navbar .navbar-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  nav.navbar .navbar-nav .nav-link {
    width: 48%; // Adjust as needed
    box-sizing: border-box;
  }

  span.navbar-text {
    width: 100%;
    justify-content: space-around;
    margin-top: 12px; // Giving some space between nav links and social icons
  }

  .social-icon {
    width: 100%;
    justify-content: space-around;
  }
}

/************ Banner Css ************

$main-font-family: "Centra", sans-serif;
$main-font-color: #fff;
$primary-bg-color: #121212;
$secondary-bg-color: #000;
$accent-color: #46b076;
*/
// .banner {
//   background: linear-gradient(135deg, #46b076, #000); // Initial gradient
//   background-size: 400% 400%; // Make it larger than the viewport
//   animation: gradientAnimation 15s ease infinite; // Apply animation

//   @keyframes gradientAnimation {
//     0% {
//       background-position: 0% 50%;
//     }
//     50% {
//       background-position: 100% 50%;
//     }
//     100% {
//       background-position:   
//  0% 50%;
//     }
//   }
.banner {
  animation: gradientAnimation 15s ease infinite;
    background: linear-gradient(135deg, #46b076, #222, #000);
    background-size: 400%;
    overflow: hidden;
    position: relative;
    height: 100vh;
    align-content: center;

    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  
  
  

  .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }

  h1 {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
  }

  button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;

    svg {
      font-size: 25px;
      margin-left: 10px;
      transition: 0.3s ease-in-out;
      line-height: 1;
      fill: #fff;
    }

    &:hover svg {
      margin-left: 25px;
    }
  }

  img, .terminal {
    animation: updown 8s linear infinite;
  }
}

.above-snow{z-index: 1;}


@keyframes updown {
  0%, 100% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}


/************ Contact Css ************/
.contact {
  background: #000; // Changed to black to match the services section
  padding: 25px;

  img {
    width: 92%;
  }

  h2 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #fff;
  }

  form {
    input, textarea {
      width: 100%;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #555; // Adjusted to match the services section
      border-radius: 20px;
      color: #fff;
      margin-bottom: 8px;
      padding: 18px 26px;
      font: 500 18px/1 sans-serif; // Use the font shorthand property
      letter-spacing: 0.8px;
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;

      &:focus {
        background: rgba(255, 255, 255, 1);
        color: #121212;
      }

      &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      }

      &:focus::placeholder {
        color: #121212;
        opacity: 0.8;
      }
    }

    button {
      font-weight: 700;
      color: #000;
      background-color: #46b076; // Adjusted to match the services section
      padding: 14px 48px;
      font-size: 18px;
      margin-top: 25px;
      border-radius: 0;
      position: relative;
      transition: 0.3s ease-in-out;

      span {
        z-index: 1;
        position: relative;
      }

      &:hover {
        color: #fff;
        background-color: #121212; // Darker background on hover
      }

      &::before {
        content: "";
        background: #121212;
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: 0.3s ease-in-out;
      }

      &:hover::before {
        width: 100%;
      }
    }
  }
}

.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background-color: #000; 
  color: #fff; 
}

.centered-heading {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #fff; 
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 25px 0px 25px 0px;
 
}

.hosting-container .service-card {
  flex: 1 1 30%;
}

.wordpress-services-container .service-card {
  flex: 1 1 24%;
}

.service-card {
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
  border: 1px solid #555; 
}

.service-card:hover {
  transform: scale(1.05);
}

.service-title {
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.service-description {
  margin-bottom: 20px;
  font-size: 16px;
  color: #46b076;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
  color: #46b076;
}

.feature-list li {
  margin-bottom: 10px;
}

.service-price {
  font-size: 18px;
  color: #46b076;
}

@media (max-width: 800px) {
  .hosting-container .service-card, 
  .wordpress-services-container .service-card {
    flex: 1 1 45%;
  }
}

@media (max-width: 600px) {
  .hosting-container .service-card, 
  .wordpress-services-container .service-card {
    flex: 1 1 100%;
  }
}
/************ Technologies Css ************/

.icons-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background-color: #000; 
  color: #fff; 
  
}

.centered-heading {
  margin-bottom: 20px;
  color: #fff; 
}

.icons-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1800px; /* Adjust based on your preference */
  margin: 25px auto 25px auto;
}

.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #222; /* Adjust based on your preference */
  padding: 15px;
  border-radius: 8px;
}

.icon-card img.icon-image {
  width: 100px; /* Adjust based on your icon size */
  height: 100px; /* Adjust based on your icon size */
  margin-bottom: 10px; /* Adjust based on your preference */
}

.icon-name, .icon-desc {
  color: #fff;
}

button {
  font-weight: 700;
  color: #000;
  background-color: #46b076; // Adjusted to match the services section
  padding: 14px 48px;
  font-size: 18px;
  
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;


  span {
    z-index: 1;
    position: relative;
  }

  &:hover {
    color: #fff;
    background-color: #121212; // Darker background on hover
  }

  &::before {
    content: "";
    background: #121212;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover::before {
    width: 100%;
  }
}



/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url("./assets/img/matrixcorridorblur2.gif");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px); /* Added blur effect */
  display: flex;
  align-items: center;
  padding: 50px;
}
.newsletter-bx {
  background: #ffffff;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer {
  color: #fff;
}


/* Media query for screens less than 1000px wide */
@media (max-width: 1000px) {
  .footer img {
    display: block;
    margin: 20px auto; /* Center the logo and add vertical spacing */
    width: 50%;
  }

  .text-center.text-sm-end {
    text-align: center !important; /* Center the text and social icons */
    margin-top: 20px; /* Add vertical spacing */
  }

  .social-icon {
    justify-content: center; /* Center the social icons */
    margin: 20px 0; /* Add vertical spacing above and below the icons */
  }
}
