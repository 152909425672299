body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#headshot {
  border-radius: 45px;
  margin-bottom: -80px;
}

.glow-on-hover {
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:hover:before {
  content: '';
  background: linear-gradient(45deg, #000, #46b076, #48ff00, #000, #46b076, #48ff00);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(35px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 25s linear infinite;
  border-radius: 10px;

}
.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 200% 0; }
  100% { background-position: 0 0; }
}

.portfolio-section {
  padding: 25px;
  background-color: #121212;
  color: #fff;
}

.centered-text {
  padding: 25px;;
  color: #fff;
  text-align: center;
}

.portfolio-section p {
  color: #b8b8b8;
  text-align: center;
  max-width: 800px;
  margin: 0 auto ;
}

.portfolio-item {
  position: relative;
  background-color: #111;
  border: 1px solid #555; 
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 340px; /* Adjusted height to maintain the aspect ratio of your images */
  transition: transform 0.3s;
}

.portfolio-item-background {
  width: 100%;
  height: 60%; /* Adjusted to give more space for the image */
  background-size: cover;
  border-radius: 10px 10px 0 0; /* Rounded corners at the top */
  transition: background-image 0.5s ease-in-out;
}

.portfolio-item-content {
  background-color: #000; /* Black background for the text section */
  padding: 25px;
  border-radius: 0 0 10px 10px; /* Rounded corners at the bottom */
}

.portfolio-item:hover {
  transform: scale(1.05);
}

@media (max-width: 576px) {
  .portfolio-item {
    margin-bottom: 20px;
  }
}
